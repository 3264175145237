<template>
    <template v-if="stepId!=null && !readOnly">
        <h5>{{$t("Bill Of Materials")}}</h5>
        <ODataGrid :data-object="dsItemsStepsMaterials" hideMultiselectColumn hideGridMenu disableFilterRow disableDeleteConfirm newRecordsPosition="bottom" noActiveRows style="max-height:400px; min-height:150px">
            <OColumn field="Product" width="250" sortable disableMenu v-slot:editor="{ modelValue: row }" editable singleClickEdit>
                <ODataLookup 
                    :data-object="dsProducts" 
                    :bind="selected => {row.Product_ID = selected.ID, row.Product = selected.Name}"
                    :value="row.Product">
                    <OColumn field="ID" width="80"></OColumn>
                    <OColumn field="Name" width="150"></OColumn>
                    <OColumn field="ProductGroup" width="250"></OColumn>
                </ODataLookup>
            </OColumn>
            <OColumn field="Unit" :headerName="$t('Unit')"  width="100" sortable disableMenu >
                
            </OColumn>
            <OColumn field="Quantity" :headerName="$t('Qty')" width="70" sortable disableMenu editable singleClickEdit v-slot:editor="{ modelValue: row }">
                <input type="text" v-model="row.Quantity">
            </OColumn>
            <OColumn field="Comment"  width="200" sortable disableMenu editable singleClickEdit></OColumn>
            
        </ODataGrid>
    </template>
    <template  v-else-if="stepId!=null && readOnly && dsItemsStepsMaterials.data.length > 0">
        <table class="table small">
            <thead>
                <tr>
                    <th scope="col">{{$t("Product")}}</th>
                    <th scope="col">{{$t("Unit")}}</th>
                    <th scope="col">{{$t("Qty")}}</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(material,index) in dsItemsStepsMaterials.data">
                    <tr>
                        <td>{{material.Product}}</td>
                        <td>{{material.Unit}}</td>
                        <td>{{material.Quantity}}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </template>

    <template  v-if="!stepId && !readOnly">
        <h5>{{$t("Bill Of Materials")}}</h5>
        <ODataGrid :data-object="dsItemsMaterials" hideMultiselectColumn hideGridMenu disableFilterRow newRecordsPosition="bottom" noActiveRows >
            <OColumn field="Product" width="250" sortable disableMenu v-slot:editor="{ modelValue: row }" editable singleClickEdit>
                <ODataLookup 
                    :data-object="dsProducts" 
                    :bind="selected => {row.Product_ID = selected.ID, row.Product = selected.Name}"
                    :value="row.Product">
                    <OColumn field="ID" width="80"></OColumn>
                    <OColumn field="Name" width="150"></OColumn>
                    <OColumn field="ProductGroup" width="250"></OColumn>
                </ODataLookup>
            </OColumn>
            <OColumn field="Unit" :headerName="$t('Unit')"  width="100" sortable disableMenu editable singleClickEdit></OColumn>
            <OColumn field="Quantity" :headerName="$t('Qty')" width="70" sortable disableMenu editable singleClickEdit v-slot:editor="{ modelValue: row }">
                <input type="text" v-model="row.Quantity" >
            </OColumn>
            <OColumn field="Comment"  width="200" sortable disableMenu editable singleClickEdit></OColumn>
        </ODataGrid>
    </template>
    <template  v-else-if="!stepId && readOnly">

    </template>
</template>

<script setup>   
    import { onMounted, onUnmounted, defineProps } from 'vue';
    import { getOrCreateDataObject, deleteDataObject } from 'o365-dataobject';
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean,
    });
    
    const vItemsMaterialsId = 'aviw_Scope_ItemsMaterials' + crypto.randomUUID();
    const vItemsStepsMaterialsId = 'aviw_Scope_ItemsStepsMaterials' + crypto.randomUUID();

    const dsItemsMaterials = getOrCreateDataObject({
        id: vItemsMaterialsId,
        viewName: 'aviw_Scope_ItemsMaterials',
        maxRecords: 50,
        uniqueTable: 'atbv_Scope_ItemsStepsMaterials',
        allowInsert: true,
        allowDelete: true,
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Product_ID", type: "number" },
             {name: "Quantity", type: "number" },
             {name: "Item_ID", type: "number" },
             {name: "Product", type: "string" },
             {name: "Unit", type: "string" }]
    });

    const dsItemsStepsMaterials = getOrCreateDataObject({
        id: vItemsStepsMaterialsId,
        viewName: 'aviw_Scope_ItemsStepsMaterials',
        maxRecords: 50,
        uniqueTable: 'atbv_Scope_ItemsStepsMaterials',
        allowInsert: true,
        allowDelete: true,
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true, 
        fields:
             [{name: "ID", type: "number" },
             {name: "Product_ID", type: "number" },
             {name: "Step_ID", type: "number" },
             {name: "Quantity", type: "number" },
             {name: "Item_ID", type: "number" },
             {name: "Product", type: "string" },
             {name: "Comment", type: "string" },
             {name: "Unit", type: "string" }]
    });

    const dsProducts = getOrCreateDataObject({
        id: 'dsProducts',
        viewName: 'aviw_Stock_Products',
        maxRecords: 50,
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string" },
             {name: "ProductGroup", type: "string" },
             {name: "ProductNo", type: "string" }]
    });

    dsItemsStepsMaterials.on("BeforeSave",(pRow)=>{
        pRow.values["Step_ID"] = props.stepId;
        pRow.values["Item_ID"] = props.itemId;
    });

    onMounted(async () => {
        await dsItemsStepsMaterials.load();
        await dsItemsMaterials.load();
        await dsProducts.load();
    })

    onUnmounted(() => {
        deleteDataObject(vItemsMaterialsId); 
        deleteDataObject(vItemsStepsMaterialsId); 
    });

    dsItemsMaterials.on("BeforeSave",(pRow)=>{
        if(pRow.values["Quantity"].indexOf(",") != -1){
            pRow.values["Quantity"] = pRow.values["Quantity"].replace(",",".");
        }
        pRow.values["Item_ID"] = props.itemId;
    })
    

    if(props.stepId != null){
        dsItemsStepsMaterials.recordSource.whereClause = "Step_ID = " + props.stepId;
        dsItemsStepsMaterials.load();
    }else{
        dsItemsMaterials.recordSource.whereClause = " Item_ID = " + props.itemId;
        dsItemsMaterials.load();
    }
    
</script>